import './BackgroudImagesStyles.css'

import React from 'react'

const ContactImage = () => {
  return (
    <>
      <div className='contactImage-bg'></div>
    </>
  )
}

export default ContactImage
