import './BackgroudImagesStyles.css'

import React from 'react'

const PrivacyPolicyImage = () => {
  return (
    <>
      <div className='privacyImage-bg'></div>
    </>
  )
}

export default PrivacyPolicyImage
