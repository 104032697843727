


import Navbar from "../Components/Navbar";
import Image from '../Components/Image'
import Footer from "./Footer";

import React from 'react'
import PrivacyPolicyImage from "./PrivacyPolicyImage";

const Privacy = () => {
  return (
    <>
      <div
        className="dropdown-list"
        >
        <Navbar bg='white' />
      </div>
      <PrivacyPolicyImage/>
    <div style={{ maxWidth: "1200px" }} className="container">
        <div className="row">
          <div className="col-md-12 pt-5 pb-3">
            <h1>Privacy Policy</h1>
          </div>
          <h4 className="py-3 fw-bolder ">1. Introduction</h4>
          <p>
          Welcome to Dark & night Services Ltd. Our Privacy Policy governs your visit to https://darkandnightservices.co.uk/ and explains how we collect, safeguard, and disclose information that results from your use of our Service.
          </p>
          <p>
          We use your data to provide and improve Service. By using the Service, you agree to the collection and use of information by this policy. Unless otherwise defined in this Privacy Policy, the terms used in this page have the same meanings as in our Terms and Conditions.
          </p>
          <p>
          Our Terms and Conditions (“Terms”) govern all use of our Service and, together with the Privacy Policy, constitute your agreement with us (“Agreement”).
          </p>
         <h4 className="fw-bolder ">2. Definitions for Privacy Policy</h4>
          <p>
          SERVICE means the https://Dark and Nightservices.co.uk/ website operated by Dark and Night Services Ltd.
          </p>
          <p>
          PERSONAL DATA means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
          </p>
          <p>
          USAGE DATA is data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
          </p>
          <p>
          COOKIES are small files stored on your device (computer or mobile device).
          </p>
          <p>
          DATA CONTROLLER means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and how any personal data are, or are to be, processed. For this Privacy Policy, we are a Data Controller of your data.
          </p>
          <p>
          DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers to process your data more effectively.</p>
          <p>
          DATA SUBJECT is any living individual who is the subject of Personal Data.</p>
          <p>THE USER is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
          <h4 className="fw-bolder ">3. Information Collection and Use</h4>
          <p>We collect several different types of information for various purposes to provide and improve our Service</p>
        </div>
        <div className="row">
        
        </div>
        <div className="row mb-5">
          <h4 className="fw-bolder">4. Types of Data Collected:</h4>
          <h4 className="fw-bolder">Personal Data</h4>
          <p>
          While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:
          </p>
          <h4 className="fw-bolder">0.1. Email address</h4>
          <p>0.2. First name and last name</p>
          <p>0.3. Phone number</p>
          <p>0.4. Address, Country, State, Province, ZIP/Postal code, City</p>
          <p>0.5. Cookies and Usage Data</p>
          <p>We may use your Data to contact you with newsletters, marketing or promotional materials, and other information that may interest you. You may opt out of receiving any or all of these communications from us by following the unsubscribe </p>
          <h4 className="fw-bolder">Usage Data Policy</h4>
          <p>
          We may also collect information that your browser sends whenever you visit our Service or access the Service by or through any device (“Usage Data”).
          </p>
          <p>
          This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Services that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
          <p>When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.</p>
          <h4 className="fw-bolder">Tracking Cookies Data</h4>
          <p>We use cookies and similar tracking technologies to track the activity on our Services and hold certain information.</p>
          <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies, such as beacons, tags, and scripts, are also used to collect and track information and improve and analyze our Services.</p>
          <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may be unable to use some portions of our Services.</p>
          <h6 style={{color:"orange"}}>Examples of Cookies Policy we use:</h6>
          <p>0.1. Session Cookies: We use Session Cookies to operate our Services</p>
          <p>0.2. Preference Cookies: We use Preference Cookies to remember your preferences and settings.</p>
          <p>0.3. Security Cookies: We use Security Cookies for security purposes.</p>
          <p>0.4. Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</p>
          <h4 className="fw-bolder">Other Data</h4>
          <p>While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at the place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, NDA agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location, and other data</p>
          <h4 className="fw-bolder">5. Use of Data</h4>
          <p>Dark & night Services Ltd. uses the collected data for various purposes:</p>
          <p>0.1. to provide and maintain our Service;</p>
          <p>0.2. to notify you about changes to our Service;</p>
          <p>0.3. to allow you to participate in interactive features of our Service when you choose to do so;</p>
          <p>0.4. to provide customer support;</p>
          <p>0.5. to gather analysis or valuable information so that we can improve our Service;</p>
          <p>0.6. to monitor the usage of our Service;</p>
          <p>0.7. to detect, prevent, and address technical issues;</p>
          <p>0.8. to fulfill any other purpose for which you provide it;</p>
          <p>0.9. to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</p>
          <p>0.10. to provide you with news, special offers, and general information about other goods, services, and events that we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</p>
          <p>0.11. in any other way we may describe when you provide the information;</p>
          <p>0.12. for any other purpose with your consent.</p>
          <h4 className="fw-bolder ">6. Retention of Data</h4>
          <p>We will retain and use your Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
          <p>Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.</p>
          <h4 className="fw-bolder ">7. Transfer of Data</h4>
          <p>Your information, including Personal Data, may be transferred to – and maintained on – computers outside your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
          <p>If you are located outside the United Kingdom and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United Kingdom and process it there.</p>
          <p>Your consent to this Privacy Policy and submission of such information represents your agreement to that transfer.</p>
          <p>Dark & night Services Ltd. will take all the steps reasonably necessary to ensure that your data is treated securely and by this Privacy Policy, and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place, including the security of your data and other personal information.</p>
          <h4 className="fw-bolder ">8. Disclosure of Data</h4>
          <p>We may disclose personal information that we collect or you provide:</p>
          <h4 className="fw-bolder ">0.1. Business Transaction</h4>
          <p>If we or our subsidiaries are involved in a merger, acquisition, or asset sale, your Data may be transferred.</p>
          <p>0.2. Other cases. We may disclose your information also:</p>
          <p>0.2.1. to our subsidiaries and affiliates;</p>
          <p>0.2.2. to contractors, service providers, and other third parties we use to support our business;</p>
          <p>0.2.3. to fulfill the purpose for which you provide it;</p>
          <p>0.2.4. for including your company’s logo on our website;</p>
          <p>0.2.5. for any other purpose disclosed by us when you provide the information;</p>
          <p>0.2.6. with your consent in any other cases;</p>
          <p>0.2.7. if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</p>
          <h4 className="fw-bolder ">9. Security of Data</h4>
          <p>The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
          <h4 className="fw-bolder ">10. Your Data Protection Rights Under the General Data Protection Regulation (GDPR)</h4>
          <p>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights covered by GDPR.</p>
          <p>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
          <p>If you wish to be informed what Personal Data we hold about you and if you want it removed from our systems, please email us at admin@Dark and Nightservices.co.uk.</p>
          <p>In certain circumstances, you have the following data protection rights</p>
          <p>0.1. the right to access, update or delete the information we have on you;</p>
          <p>0.2. the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</p>
          <p>0.3. the right to object. You have the right to object to our processing of your Personal Data;</p>
          <p>0.4. the right of restriction. You have the right to request that we restrict the processing of your personal information;</p>
          <p>0.5. the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable, and commonly used format;</p>
          <p>0.6. the right to withdraw consent. You also have the right to withdraw your consent at any time when we rely on your consent to process your personal information;</p>
          <p>Please note that we may ask you to verify your identity before responding to such requests. Please note we may not be able to provide Service without some necessary data.</p>
          <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please get in touch with your local data protection authority in the European Economic Area (EEA).</p>
          <h4 className="fw-bolder ">11. Service Providers</h4>
          <p>We may employ third-party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services, or assist us in analyzing how our Service is used.</p> 
          <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p> 
          <h4 className="fw-bolder ">12. Analytics</h4>
          <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
          <h4 className="fw-bolder ">13. CI/CD tools</h4>
          <p>We may use third-party Service Providers to automate the development process of our Service.</p>
          <h4 className="fw-bolder ">14. Behavioral Remarketing</h4>
          <p>We may use remarketing services to advertise on third-party websites after you visit our Service. Our third-party vendors and we use cookies to inform, optimize, and serve ads based on your past visits to our Service.</p>
          <h4 className="fw-bolder ">15. Links to Other Sites</h4>
          <p>Our Service may contain links to other sites not operated by us. You will be directed to that third-party site if you click a third-party link. We strongly advise you to review every site’s privacy policy.</p>
          <p>We have no control over and assume no responsibility for any third-party sites or services’ content, privacy policies, or practices.</p>
          <h4 className="fw-bolder ">16. Children’s Privacy</h4>
          <p>Our Services are not intended for use by children under the age of 18 (“Child” or “Children”).</p>
          <p>We do not knowingly collect personally identifiable information from children under 18. If you become aware that a Child has provided us with Personal Data, please get in touch with us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p>
          <h4 className="fw-bolder ">17. Changes to This Privacy Policy</h4>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by updating this page.</p>
          <p>You are advised to review this Privacy Policy periodically for any changes.</p>
          <p>Last date: 2023-08-07</p>
          <h4 className="fw-bolder ">20. Contact Us</h4>
          <p className="pb-5">If you have any questions about this Privacy Policy, please get in touch with us by emailing: admin@Dark and Nightservices.co.uk.</p>
        </div>
      </div>
      <Image/>
      <Footer/>
    </>
  )
}

export default Privacy
