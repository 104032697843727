import './ImageStyles.css'

import React from 'react'

const Image = () => {
  return (
    <div className='background'>
    </div>
  )
}

export default Image
