
import './BackgroudImagesStyles.css'

import React from 'react'

const AboutPage = () => {
  return (
    <>
      <div className='aboutImage-bg'></div>
    </>
  )
}

export default AboutPage
